<template>
    <div class="page-layout-container">
        <div class="page-layout-main">
            <FullPageLoadingSpinner :show="pageIsLoading" />

            <template v-if="!pageIsLoading">
                <Breadcrumbs :items="breadcrumbs" />
                <UnderlinedTitle class="user-report-title" type="h1"
                    >Text Input Responses</UnderlinedTitle
                >
                <div class="input-report-content">
                    <div class="input-report-metadata-container">
                        <div class="convo-name">
                            <h2>CONVO: {{ convo.name }}</h2>
                        </div>
                        <div class="convo-content-question">
                            {{ message }}
                        </div>
                        <div class="total-skips">
                            Total Number of Skips: {{ textInputReport.numberOfSkips }}
                        </div>
                        <div
                            class="download-report-container"
                            :class="{
                                'download-report-container--disabled': processingReport,
                            }"
                        >
                            <IconButton
                                v-tooltip.bottom="tooltipContent"
                                class="download-report-icon"
                                icon="download-data"
                                @click="generateRawData"
                            />
                        </div>
                    </div>
                    <Datatable
                        :data="textInputReport.inputTextData"
                        :columns="tableColumns"
                    ></Datatable>
                    <Paging
                        :total="textInputReport.inputTextData.total"
                        :size="textInputReport.inputTextData.size"
                        :page="textInputReport.inputTextData.page"
                        @pageSelected="selectPage"
                    />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Datatable from '@/components/Datatable.vue';
import Breadcrumbs from '@/components/ui/Breadcrumbs';
import convoTypes from '../json/convoTypes';
import { Breadcrumb } from '@/store/models/breadcrumb.dto.ts';
import FullPageLoadingSpinner from '../components/ui/FullPageLoadingSpinner';
import { LoadingFlag } from '@/store/enums/loadingIds.enum.ts';
import UnderlinedTitle from '@/components/ui/UnderlinedTitle';
import IconButton from '@/components/ui/IconButton';
import Paging from '@/components/Paging';
import { ReportClient } from '../api/report';
import FileSaver from 'file-saver';
import * as io from 'socket.io-client';

let socket;

export default {
    name: 'UserInputReport',
    components: {
        FullPageLoadingSpinner,
        Breadcrumbs,
        UnderlinedTitle,
        IconButton,
        Paging,
        Datatable,
    },
    data() {
        return {
            breadcrumbs: [],
            message: 'loading',
            id: '',
            fileNameKey: '',
            processingReport: false,
            tooltipContent: '',
            tooltipData: {
                downloadReport: 'Download User Input Report',
                generateReport: 'Generate User Input Report',
                processReport: 'Processing User Input Report. Please wait...',
            },
            convo: {
                name: '',
                createdDate: '',
                modifiedDate: '',
                modifiedTime: '',
            },
            tableColumns: [
                {
                    value: 'memberId',
                    header: 'MEMBER ID',
                    width: '30%',
                    type: 'member',
                },
                {
                    value: 'answer',
                    header: 'TEXT',
                    width: '70%',
                    type: 'answer',
                    class: 'datatable-wrap-content',
                },
            ],
        };
    },
    computed: {
        ...mapGetters('channel', {
            channelDetails: 'channelDetails',
        }),
        ...mapGetters('convoDetails', {
            convoDetailsView: 'convoDetailsView',
        }),
        ...mapGetters('reports', {
            textInputReport: 'textInputReport',
        }),
        pageIsLoading() {
            return (
                this.$wait.is(LoadingFlag.ReportGetText) ||
                !(this.convo.name && this.convo.name.length)
            );
        },
        hasNoData() {
            return (
                !this.textInputReport &&
                this.textInputReport.inputTextData &&
                this.textInputReport.inputTextData.length === 0
            );
        },
        convoType() {
            const convoType = convoTypes.find((t) => t.name === this.convoDetailsView.convo.type);
            if (convoType) {
                return convoType.title;
            }
            return 'Convo';
        },
    },
    async created() {
        const convoId = this.$route.params.convoId;
        this.id = this.$route.params.id;
        this.fileNameKey = `${convoId}-${this.id}`;
        const filename = localStorage.getItem(this.fileNameKey);
        await this.setChannelDetails();
        await this.loadConvo({
            id: convoId,
            channelCode: this.channelDetails.channel.code,
        });
        await this.getUserInputReport({ id: this.id });
        this.setBreadcrumbs();
        this.setConvoTypesAndDetails();
        this.message = this.textInputReport.question;
        this.setupWebSocket();
        this.setTooltipLabel();
        if (filename) {
            self.processingReport = true;
            socket.connect();
            socket.emit('report-request-input', {
                content: { id: this.id },
                filename,
                authorization: this.$store.getters['auth/accessToken'],
                source: 'sorkin',
            });
        }
    },
    async beforeDestroy() {
        this.fileNameKey = '';
        this.clearState();
    },
    methods: {
        ...mapActions('convoDetails', {
            loadConvo: 'loadConvo',
        }),
        ...mapActions('channel', {
            setChannelDetails: 'setChannelDetails',
        }),
        ...mapActions('reports', {
            getUserInputReport: 'getUserInputReport',
        }),
        async selectPage(event, page) {
            const options = {
                page: page,
                id: this.id,
                size: this.textInputReport.inputTextData.size,
            };
            this.getUserInputReport(options);
        },
        setupWebSocket: function () {
            const self = this;
            socket = io(process.env.VUE_APP_API, {
                autoConnect: false,
                transports: ['websocket'],
            });
            socket.on('connect', function () {
                console.log('connected to socket');
            });
            socket.on('exception', function (data) {
                self.$log.error('error while getting user input report data', data);
                self.clearState();
            });
            socket.on('disconnect', function () {
                console.log('disconnected from socket');
            });

            socket.on('report-input-done', function () {
                const filename = localStorage.getItem(self.fileNameKey);
                if (filename) {
                    self.processingReport = false;
                    self.setTooltipLabel();
                }
            });

            socket.on('report-input-error', function () {
                self.clearState();
                socket.disconnect();
            });

            socket.on('report-input-requested', function (data) {
                if (self.fileNameKey) {
                    localStorage.setItem(self.fileNameKey, data.filename);
                }
            });
        },
        setTooltipLabel() {
            const filename = localStorage.getItem(this.fileNameKey);
            if (this.processingReport) {
                this.tooltipContent = this.tooltipData.processReport;
            } else {
                if (filename) {
                    this.tooltipContent = this.tooltipData.downloadReport;
                } else {
                    this.tooltipContent = this.tooltipData.generateReport;
                }
            }
        },
        setBreadcrumbs() {
            const breadcrumbs = [];
            let convoLink;
            if (this.channelDetails.channel.code) {
                convoLink = {
                    name: 'convo-list',
                    params: { channelCode: this.channelDetails.channel.code },
                };
            }
            breadcrumbs.push(new Breadcrumb(this.channelDetails.channel.name, convoLink));
            breadcrumbs.push(
                new Breadcrumb(`${this.convoType} CONVO: ${this.convoDetailsView.convo.name}`),
            );

            this.breadcrumbs = breadcrumbs;
        },
        setConvoTypesAndDetails() {
            const convoDetails = this.convoDetailsView.convo;
            this.convo.name = convoDetails.name;
        },
        generateRawData: async function () {
            const filename = localStorage.getItem(this.fileNameKey);
            const accessToken = this.$store.getters['auth/accessToken'];
            if (!filename) {
                socket.connect();
                this.processingReport = true;
                this.setTooltipLabel();
                socket.emit('report-request-input', {
                    content: { id: this.id },
                    filename,
                    authorization: accessToken,
                    source: 'sorkin',
                });
            } else {
                this.downloadRawReportS3(filename);
                this.clearState();
            }
        },
        downloadRawReportS3: function (filename) {
            const key = `reports/V2/${filename}/${filename}`;
            ReportClient.downloadReport(key)
                .then((response) => {
                    console.log('filesaver', FileSaver);
                    FileSaver.saveAs(response, filename);
                })
                .catch((err) => {
                    this.$log.error('error while getting report', err);
                });
        },
        clearState: function () {
            if (localStorage.getItem(this.fileNameKey)) {
                localStorage.removeItem(this.fileNameKey);
            }
            this.processingReport = false;
            this.setTooltipLabel();
        },
    },
};
</script>

<style lang="scss" scoped>
.reports-spinner {
    @include center();
    position: absolute;
}

.page-layout-main {
    font-family: $font-family-default;
}

.user-report-title {
    margin-top: 7px;
}

.input-report-content {
    .input-report-metadata-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-between;

        .convo-name {
            font-size: 20px;
            margin-bottom: 10px;
            display: flex;
            flex: 0 0 auto;
            width: 100%;
        }

        .convo-content-question {
            font-size: $font-size-sm;
            color: $alternate-text-color;
            margin: 6px 0;
            width: 80%;
        }
        .total-skips {
            display: flex;
            width: 15%;
            font-size: $font-size-sm;
            color: $alternate-text-color;
        }
        .download-report-container {
            display: flex;
            width: 5%;
            &--disabled {
                cursor: not-allowed;
                button {
                    cursor: not-allowed;
                    pointer-events: none;
                }
                opacity: 0.5;
            }
        }
        .download-report-icon {
            height: 15px;
            width: 22px;
        }
    }
}
</style>
